<template lang="pug">
a-modal(
:closable="false"
v-model="visible"
:width="1000"
@ok="ok"
@cancel="cancel")
    app-h2(
        content="Política de Privacidad")
    iframe(class="document" src="https://sales-journey.s3-sa-east-1.amazonaws.com/media/politica_privacidad.pdf")
</template>

<script>
import AppH2 from "@/components/atoms/AppH2.vue";
export default {
  components: {
    AppH2
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ok: {
      type: Function,
      default: () => {}
    },
    cancel: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
.document {
  width: 100% !important;
  height: 50vh;
  margin: 15px auto;
}
</style>
