<template lang="pug">
Layout
  Header
    div(:style="{ marginRight: '60px', display: 'flex'}")
      div(class="comercial-info-container")
        app-text(
          :customStyle="{width: '100%', margin: '5% 0'}"
          :content="seller",
          className="small-light inside-container")
        app-text(
          content="Comercial",
          className="nano-light inside-container"
          :customStyle="{width: '100%'}")
      app-text(
        @click="exitClient"
        :customStyle="{marginTop: '10%'}"
        className="small-error link"
        content="Cerrar")
  layout-content
    app-h2(
        className="regular",
        :customStyle="{ width: '100%' }",
        content="Salesroom"
        :style="{margin: '30px',fontWeight:'600',fontSize:'24px'}"
      )
    .showroom-client__topsection(
      :style="{margin:'30px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }"
    )
      div(:style="{ width: '334px', display: 'flex', flexWrap: 'wrap', marginRight:'80px'}")
        app-text(
          className="regular-bold",
          content="¡Bienvenido!"
          :style="{marginBottom: '20px'}")
        app-text(
          className="regular",
          content="A continuación podrá visualizar las muestras enviadas por el comercial"
          :style="{lineHeight: '200%'}"
        )
      div(:style="{ width: '400px', display: 'flex', flexWrap: 'wrap' }")
        div(:style="{display:'flex'}")
          app-text(
          className="regular-bold",
          content="Comparta esta muestra",
          :style="{marginBottom: '20px'}")
          invite-form(:style="{margin:'0 30px'}")
        app-text(
          className="regular",
          content="Puede agregar más personas a esta reunión"
          :style="{lineHeight: '200%'}"
        )
    layout-content
      div(
        :style="{ height: '40vh', margin: 'auto 30px', textAlign: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column',backgroundColor:'white' }"
      )
        app-h2(
          :style="{ fontSize: '24px',padding:'0px 60px'}"
          :customStyle="{ width: '100%' }",
          content=" Presione el siguiente botón para empezar la vídeollamada con el comercial, es importante que una vez haya entrado al entorno de presentación se una a la vídeollamada pulsando Join Meeting."
        )
        app-button(:handler="handleModalTerms" type="primary", label="Comenzar" :style="{height:'40px', width:'150px'}")
  terms-modal(:visible="modal" :ok="goToShowRoomClient" :cancel="handleModalTerms")
</template>
<script>
//import assets
import watermark from "@/assets/watermark.png";
//import atoms components
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";

//import molecules components
import TermsModal from "@/components/molecules/TermsModal.vue";
// import organisms components
import Layout from "@/components/organisms/Layout.vue";
import LayoutContent from "@/components/organisms/LayoutContent.vue";
import Header from "@/components/organisms/Header.vue";

//Import customs components from this component
import InviteForm from "@/components/customComponents/ShowRoomClient/InviteForm.vue";

//Import validate entry endpoint
import { SalesRoomService } from "@/common/api.service.js";

//Vuex imports
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "ClientLobby",
  components: {
    Layout,
    Header,
    LayoutContent,
    AppText,
    AppH2,
    AppButton,
    InviteForm,
    TermsModal
  },
  data() {
    return {
      watermark,
      seller: undefined,
      modal: false
    };
  },
  created() {
    this.seller =
      this.$session.get("ShowRoomClient").seller.name || "David Abreu";
  },
  methods: {
    goToShowRoomClient: function() {
      if (
        this.$route.params.user_entry_time === null ||
        this.$route.params.user_entry_time === undefined
      ) {
        SalesRoomService.attendantJoin(this.$session.get("ShowRoomClient").id);
      }
      this.$router.push({ name: "ShowRoomClient" });
    },
    handleModalTerms() {
      this.modal = !this.modal;
    },
    exitClient() {
      this.$store.dispatch(LOGOUT);
      this.$session.destroy();
      this.$router.push({
        name: "LoginClient",
        params: { finishedShowRoom: false }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.comercial-info-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
}
</style>
